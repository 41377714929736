import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";

import imageNotFound from "../assets/image/image-not-found.jpg";
import { DataContext } from "../context/DataContext";
import { validation } from "../utils/validation";

const Companies = () => {
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const { register, handleSubmit, watch, setValue } = useForm();
  const { clients, getAllClientList } = useContext(DataContext);
  const watchKeyword = watch("keyword");

  const handleSearchInput = async (data) => {
    const searchKeyword = data.toLocaleLowerCase();
    const response = clients.filter(
      (client) =>
        client.status === "active" &&
        (client.company.toLocaleLowerCase().includes(searchKeyword) ||
          client.city.toLocaleLowerCase().includes(searchKeyword))
    );
    setSearchResults(response);
  };

  const fetchClientList = async () => {
    const response = await getAllClientList();
    const activeClients = response.filter(
      (client) => client.status === "active"
    );
    setSearchResults(activeClients);
    setLoading(false);
  };

  useEffect(() => {
    fetchClientList();
    return () => setLoading(true);
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { keyword } = data;
      const searchKeyword = keyword.toLocaleLowerCase();
      const response = clients.filter(
        (client) =>
          client.status === "active" &&
          (client.company.toLocaleLowerCase().includes(searchKeyword) ||
            client.city.toLocaleLowerCase().includes(searchKeyword))
      );
      setSearchResults(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PageWrapper>
        <div className="bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-xs-12 ">
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  className="search-form search-2-adjustment ml-lg-0 ml-md-15"
                >
                  <div className="filter-search-form-2 bg-white rounded-sm shadow-7 pr-6 py-6 pl-6">
                    <div className="filter-inputs">
                      <div className="form-group position-relative w-100">
                        <input
                          className="form-control focus-reset pl-13"
                          type="text"
                          id="keyword"
                          name="keyword"
                          placeholder="Company name or city"
                          ref={register(validation.search)}
                          onChange={(e) => handleSearchInput(e.target.value)}
                        />

                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                        </span>
                      </div>
                    </div>
                    <div className="button-block">
                      <button className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase focus-reset">
                        Search
                      </button>
                    </div>
                  </div>
                </form>

                <div className="pt-12 ml-lg-0 ml-md-15">
                  <div className="d-flex align-items-center justify-content-between">
                    {loading ? (
                      <Skeleton width={200} height={26} className="mb-2" />
                    ) : (
                      <h5 className="font-size-4 font-weight-normal text-default-color">
                        <span className="heading-default-color mr-2">
                          {searchResults.length}
                        </span>
                        results for{" "}
                        <span className="heading-default-color">
                          {watchKeyword || "All"}
                        </span>
                      </h5>
                    )}
                  </div>

                  <div className="pt-6">
                    {loading ? (
                      <div className="row justify-content-center">
                        {[1, 2, 3, 4].map((index) => (
                          <div key={index} className="col-12 col-lg-6">
                            <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                              <div className="d-block mb-7">
                                <Skeleton width={80} height={80} />
                              </div>
                              <h2 className="mt-n4 ml-n1">
                                <Skeleton width={200} height={42} />
                              </h2>
                              <ul className="list-unstyled mb-1 card-tag-list">
                                <li>
                                  <Skeleton width={100} height={24} />
                                </li>
                                <li>
                                  <Skeleton width={100} height={24} />
                                </li>
                                <li>
                                  <Skeleton width={80} height={24} />
                                </li>
                              </ul>
                              <div className="card-btn-group">
                                <Skeleton
                                  height={44}
                                  containerClassName="w-100"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row justify-content-left">
                        {searchResults.map((client, index) => (
                          <div key={index} className="col-12 col-lg-6">
                            <div className="bg-white px-8 pt-9 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">
                              <div className="d-block mb-7">
                                <Link
                                  to={`/client-profile/${client?.account_id}`}
                                  state={{ id: client.account_id }}
                                >
                                  {client?.imageUrl ? (
                                    <img
                                      src={client.imageUrl}
                                      alt={`${client?.company} devshop image`}
                                      width={100}
                                    />
                                  ) : (
                                    <img
                                      src={imageNotFound}
                                      alt="Image Not Found"
                                      width={100}
                                    />
                                  )}
                                </Link>
                              </div>
                              <h2 className="mt-n4">
                                <Link
                                  to={`/client-profile/${client?.account_id}`}
                                  state={{ id: client.account_id }}
                                  className="font-size-7 text-black-2 font-weight-bold mb-4"
                                >
                                  {client?.company}
                                </Link>
                              </h2>
                              <ul className="list-unstyled mb-1 card-tag-list align-items-start h-84">
                                <li>
                                  <Link
                                    to="/#"
                                    onClick={(e) => e.preventDefault()}
                                    className="bg-regent-opacity-15 text-denim font-size-3 rounded-3"
                                  >
                                    <i className="icon icon-pin-3 mr-2 font-weight-bold"></i>{" "}
                                    {client?.city}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/#"
                                    onClick={(e) => e.preventDefault()}
                                    className="bg-regent-opacity-15 text-text-orange font-size-3 rounded-3"
                                  >
                                    <i className="fa fa-industry mr-2 font-weight-bold"></i>{" "}
                                    {client?.industry || "N/A"}
                                  </Link>
                                </li>
                                {client?.jobs.filter((job) =>
                                  ["pending", "read"].includes(job.status)
                                ).length === 0 ? null : (
                                  <li>
                                    <Link
                                      to="/#"
                                      onClick={(e) => e.preventDefault()}
                                      className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3"
                                    >
                                      <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                      {
                                        client?.jobs.filter((job) =>
                                          ["pending", "read"].includes(
                                            job.status
                                          )
                                        ).length
                                      }
                                    </Link>
                                  </li>
                                )}
                              </ul>
                              <div className="card-btn-group">
                                <Link
                                  to={`/client-profile/${client?.account_id}`}
                                  state={{ id: client.account_id }}
                                  className="btn btn-outline-mercury text-black-2 text-uppercase btn-medium rounded-3 w-100 focus-reset"
                                >
                                  See company{" "}
                                  <i className="fas fa-long-arrow-alt-right font-weight-bold ml-4 font-size-4"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default Companies;
